<template>
  <div class="reference-block scrollbar scrollbar--secondary">
    <div
      id="contract-block"
      class="reference-block-link"
    >
      <router-link :to="{ name: 'contract-block-services' }" class="links-item">
        <div class="link-flex">
            <div style="width: 100%;">
              {{ getByHotSpotRouteTitleMenu('contract-block-services') }}
            </div>
          <div v-if="isActiveHotSpot('contract-block-services')?.id" class="hotspot"
          @mouseenter="$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
              hoverEvent: $event,
              tooltipText: currentTooltipForHotSpot('contract-block-services'),
            })" 
          @mouseleave="$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');" style="margin-left: -20px;">
          Новое
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  methods: {
    isActiveHotSpot(menu) {
      const rusNameMenu = this.getByHotSpotRouteTitleMenu(menu)
      const result = this.allListActiveHotSpots.find(item => {
        return item.menu === rusNameMenu
      });
      return result;
    },
    currentTooltipForHotSpot(menu) {
      const hotSpot = this.isActiveHotSpot(menu);
      if (Object.entries(hotSpot).length &&  hotSpot.tooltip) {
        return hotSpot.tooltip
      } 
      return ''
    }
  },
  computed: {
    ...mapGetters({
      userCan: "user/userCan",
      getByHotSpotRouteTitleMenu: "hotspots/getByHotSpotRouteTitleMenu",
      allListActiveHotSpots: "hotspots/allListActiveHotSpots",
    })
  }
};
</script>

<style lang="scss" scoped>
.reference-block {
  padding: 20px 25px;
  z-index: 1;
  display: grid;
  row-gap: 20px;
  position: absolute;
  top: 52px;
  left: 0;
  width: 327px;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 566px;
  background: #1d4b7f;
  border-radius: 5px;

  &::after {
    margin-top: 2px;
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 398px;
    height: calc(100% - 2px);
    background: #1d4b7f;
    box-shadow: 4px 4px 8px 2px rgba(57, 134, 144, 0.5);
    border-radius: 5px;
  }

  &-link {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #ffffff;
    height: 100%;

    & a {
      display: inline-block;
      width: 100%;
      height: 100%;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #ffffff;
    }

    &:hover {
      & a {
        color: #44ff8f;
      }
    }
  }
  .link-report {
    color: #ffffff;
    &:hover {
      color: #44ff8f;
    }
  }
  .link-flex {
    display: flex;
    align-items: center
  }
}
</style>

<style>
#contract-block-services-page-title-clear {
  margin-top: 25px;
  padding: 0 30px;
}
</style>