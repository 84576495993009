<template>
  <div class="">
    <transition name="fade-message">
      <div class="" v-if="viewMessenger" @click="viewMessenger = false">
        <button class="chat-button"> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"> <path d="M20 2H4c-1.1 0-2 .9-2 2v18l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm0 14H6l-2 2V4h16v12z"/> </svg> </button>
      </div>
  </transition>
  <transition name="fade-message">
    <div class="messenger messenger-open" v-if="!viewMessenger" >
        <div class="messenger-header" >
          <div class="messenger-header-box" >
            <div class="messenger-header-box-item"> </div>
            <div
              @click="closeMessenger"
              class="messenger-header-box-arrow"
            >
              <img src="@/assets/restyle/images/close_x_white.svg" alt="">
            </div>
          </div>
        </div>
        <div class="messenger-body" >
          <div class="loader-wrapper" v-if="loadChats">
            <img class="loader-gif" src="@/assets/loading_mini_green.gif" alt="" />
          </div>
          <messenger-body 
            v-else
            @createChat="fetchChats"
            ref="refMessengerBody"
          />
        </div>
        <div class="messenger-footer"  >
          <div class="messenger-footer-box" v-if="getDetailChat">
            <div class="messenger-footer-box-input">
              <input type="text" v-model="textMessage">
            </div>
            <div class="messenger-footer-box-send" @click="sendMessage">
              <img src="@/assets/restyle/images/arrow_send.svg" alt="">
            </div>
          </div>
        </div>
      </div>
  </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { request } from "@/_helpers";
import messengerBody from "./messengerBody"

export default {
  components: {
    messengerBody
  },
  data() {
    return {
      viewMessenger: true,
      textMessage: '',
      listChats: [],
      loadChats: false,
      loadSendMessage: false,
    };
  },
  mounted() {
    this.fetchChats();

    document.body.addEventListener('keyup', ({key}) => {
      if (key === "Enter") {
        if (!this.viewMessenger) {
          this.sendMessage()
        }
      }
    });
  },
  watch: {
    
  },
  computed: {
    ...mapGetters({
      getChats: 'messenger/getChats',
      getDetailChat: 'messenger/getDetailChat',
    })
  },
  methods: {
    fetchChats() {
      this.loadChats = true;

      request(`${process.env.VUE_APP_WN}/chat`, 'get')
        .then(res => {
          this.$store.dispatch('messenger/setChats', res.data);          
        })
        .finally(()=> {
          this.loadChats = false;
        })
    },
    sendMessage() {
      if (!this.loadSendMessage && this.getDetailChat && this.textMessage) {
        this.loadSendMessage = true;

        request(`${process.env.VUE_APP_WN}/chat/${this.getDetailChat.id}/send`, 'POST', { text: this.textMessage })
          .then(res => {
            if (res.success) {
              this.textMessage = '';
              this.$store.dispatch('messenger/setDetailChat', { ...this.getDetailChat, data: [...res.data.reverse(), ...this.getDetailChat.data.reverse()]});
              this.$nextTick(() => {
                this.$refs.refMessengerBody.scrollToBottom()
              })
            }
          })
          .finally(()=> {
            this.loadSendMessage = false;
          })
      }
    },
    closeMessenger() {
      this.viewMessenger = true;
      this.$store.dispatch('messenger/resetState');
      this.fetchChats();
    },

  },
};
</script>

<style lang="scss">
.loader-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.loader-gif {
  width: 150px;
}

.messenger {
  z-index: 1001;
  position: fixed;
  right: 20px;
  bottom: 52px;
  display: flex;
  flex-direction: column;
  width: 450px;
  background-color: transparent;
  color: white;
  box-shadow: 0px 12.2756px 32.2234px 6.13779px rgba(129, 184, 235, 0.15);
  color: black;
  height: 650px;

  &-header {
    padding: 10px;
    cursor: pointer;
    background-color: #1d4b7f;
    color: white;
    border-radius: 10px 10px 0 0;

    &-box {
      display: flex;
      justify-content: space-between;

      &-arrow {
        margin-right: 10px;
        width: 15px;
        transition: all 0.5s ease;
        transform: rotate(-90deg);
      }
    }
  }

  &-body {
    overflow: auto;
    height: 100%;
    background-color: rgb(248, 251, 250);
  }

  &-footer {
    padding: 20px;
    overflow-x: auto;
    overflow-y: hidden;
    margin-top: auto;
    background-color: #1d4b7f;
    color: white;
    border-radius: 0 0 10px 10px;

    &-box {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &-input {
        margin-right: 20px;
        height: 35px;
        width: 100%;
        border-radius: 5px;
        background-color: #fff;
        color: $color-page-text;
        line-height: 0;

        & input {
          padding-left: 8px;
          width: 100%;
          height: 100%;
        }
      }

			&-send {
        cursor: pointer;
			}
    }
  }
}

.chat-button {
  z-index: 1001;
  position: fixed;
  right: 20px;
  bottom: 52px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: linear-gradient(138deg, #1d9c95, #1d106f);
  box-shadow: 0 4px 15px rgba(37, 99, 235, 0.3);
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  overflow: hidden;
}

.chat-button::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  transform: scale(0);
}

.chat-button svg {
  width: 28px;
  height: 28px;
  fill: white;
  transition: all 0.3s ease;
}

.chat-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 20px rgba(37, 99, 235, 0.4);
}

.chat-button:hover svg {
  transform: scale(1.1);
  fill: #43f58d;
}

.chat-button:active {
  transform: translateY(0);
  box-shadow: 0 2px 10px rgba(37, 99, 235, 0.2);
}

@media (max-width: 768px) {
  .chat-button {
    width: 50px;
    height: 50px;
    right: 20px;
    bottom: 20px;
  }
  
  .chat-button svg {
    width: 24px;
    height: 24px;
  }
}


.fade-message-enter-active,
.fade-message-leave-active {
  transition: opacity 0.4s ease-in-out;
  opacity: 1;
}

.fade-message-enter,
.fade-message-leave-active {
  opacity: 0;
}
</style>
