yarn <template>
  <div class="messenger-body-wrap">
    <div class="messenger-body-wrap-menu">
      <div
        @click="createChat"
        @mouseenter="$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', { hoverEvent: $event, tooltipText: 'Добавить новый чат' }); isPlusIconHoverActive = true"
        @mouseleave="$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP'); isPlusIconHoverActive = false"
        class="mt-2 text-center cursor-pointer"
      >
        <img 
          v-if="isPlusIconHoverActive" 
          src="@/assets/om_icons/plus-icon-active_green.svg"
          class="plus-icon-wrapper"
        >
        <img v-else src="@/assets/om_icons/plus-icon_white.svg" alt="">
      </div>
      <div 
        class="custom-folder"
        @click="$store.dispatch('messenger/setDetailChat', null);"
        @mouseenter="isFolderHoverActive = true"
        @mouseleave="isFolderHoverActive = false"
      >
      <img src="@/assets/restyle/images/shared/icons/custom_folder_green.svg" v-if="isFolderHoverActive"  >
      <img src="@/assets/restyle/images/shared/icons/custom_folder_white.svg" v-else >
        Чаты
      </div>
    </div>
    <div class="parent-wrapper-detail" >
      <div class="loader-wrapper" v-if="getLoadingDetailChat">
        <img class="loader-gif" src="@/assets/loading_mini_green.gif" alt="" />
      </div>
      <template v-else>
        <messengerDetailChat 
          v-if="getDetailChat"
          :getDetailChat="getDetailChat"
          ref="refMessengerDetailChat"
        />
        <messengerListChat 
          v-else
        />
      </template>
    </div>
  </div>
</template>

<script>
import { request } from "@/_helpers";
import { mapGetters } from 'vuex';  
import messengerListChat from "./messengerListChat"
import messengerDetailChat from "./messengerDetailChat"

export default {
  name: 'messenger-body',
  components: {
    messengerListChat,
    messengerDetailChat,
  },
  data() {
    return {
      isPlusIconHoverActive: false,
      isFolderHoverActive: false,
    }
  },
  computed: {
    ...mapGetters({
      getChats: 'messenger/getChats',
      getDetailChat: 'messenger/getDetailChat',
      getLoadingDetailChat: 'messenger/getLoadingDetailChat',
    })
  },
  methods: {
    createChat() {
      request(`${process.env.VUE_APP_WN}/chat/create`, 'POST')
        .then(res => {
          this.$emit('createChat')
        })
        .finally(()=> {
          this.loadChats = false;
        })
    },
    scrollToBottom() {
      this.$refs.refMessengerDetailChat.scrollToBottom()
    }

  },
}
</script>

<style lang="scss" scoped>
.messenger-body-wrap {
  position: relative;
  display: grid;
  grid-template-columns: 50px 1fr;
  height: 100%;
  // grid-template-columns: 1fr;

  &-menu {
    padding-top: 25px;
    border-right: 1px solid #D1D9E6;
    background: linear-gradient(266deg, #1D9C95 0%, #1d4b7f 100%);
  }
}

.cursor-pointer {
  cursor: pointer;
}

.loader-wrapper {
  text-align: center;
  height: 100%;
}

.custom-folder {
  margin-top: 15px;
  font-size: 12px;
  text-align: center;
  // color: $color-simple-text;
  color: #fff;
  cursor: pointer;
}

.parent-wrapper-detail {
  // padding-bottom: 10px;
  height: 100%;
  overflow: hidden;
}
</style>